import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/clients-reviews",
        name: "clients-reviews",
        component: () => import("@/views/ClientsReviews.vue"),
      },
      {
        path: "/landing-page",
        name: "landing-page",
        component: () => import("@/views/LandingPage.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/UserList.vue"),
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        component: () => import("@/views/SubscriptionList.vue"),
      },
      {
        path: "/plans",
        name: "plans",
        component: () => import("@/views/Plans.vue"),
      },
      {
        path: "/coupons",
        name: "coupons",
        component: () => import("@/views/Coupons.vue"),
      },
      {
        path: "/html-books",
        name: "html-books",
        component: () => import("@/views/BookList.vue"),
      },
      {
        path: "/html-books/:id",
        name: "html-book",
        component: () => import("@/views/CreateBook.vue"),
      },
      {
        path: "/html-books/create-book",
        name: "create-book",
        component: () => import("@/views/CreateBook.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((route) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (route.name != "create-book") {
    store.dispatch(Actions.VERIFY_AUTH, { apiToken: JwtService.getToken() });
  }
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
